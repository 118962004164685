import React, { useState } from 'react'
import { GameContextProvider } from 'context/Game.context'
import { AvatarContextProvider } from 'context/Avatar.context'
import { EventContextProvider } from 'context/Event.context'
import Game from './components/game/Game'
import GameCountDown from './components/gameCountDown/GameCountDown'
import GameEndScreen from './components/gameEndScreen/GameEndScreen'
import Menu from './components/menu/Menu'
import './App.scss'

function App() {
  const [showMenu, setShowMenu] = useState(true)
  const [showGameCountDown, setShowGameCountDown] = useState(false)
  const [gameActive, setGameActive] = useState(false)
  const [showEndMenu, setShowEndMenu] = useState(false)

  const closeMenu = () => {
    setShowMenu(false)
    setShowGameCountDown(true)
  }

  const startGame = () => {
    setGameActive(true)
    setTimeout(() => {
      setShowGameCountDown(false)
    }, 1000)
  }

  const restartGame = () => {
    setShowEndMenu(false)
    setShowGameCountDown(true)
  }

  const endGame = () => {
    setShowEndMenu(true)
    setTimeout(() => {
      setGameActive(false)
    }, 3100)
  }

  return (
    <div className="App">
      <GameContextProvider>
        <AvatarContextProvider>
          <EventContextProvider>
            {showMenu && <Menu closeMenu={closeMenu} />}
            {!showMenu && showGameCountDown && (
              <GameCountDown startGame={startGame} />
            )}
            {gameActive && !showMenu && <Game endGame={endGame} />}
            {showEndMenu && <GameEndScreen restartGame={restartGame} />}
          </EventContextProvider>
        </AvatarContextProvider>
      </GameContextProvider>
    </div>
  )
}

export default App
