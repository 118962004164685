import React, { useEffect, useState } from 'react'
import styles from './CloudAnimation.module.scss'
import balloon from '../../../assets/union.png'

interface CloudAnimationProps {
  onSlideOut?: () => void
  message: string
}

const CloudAnimation = ({ onSlideOut, message }: CloudAnimationProps) => {
  const [backgroundStyle, setBackgroundStyle] = useState(
    styles['game-background']
  )
  useEffect(() => {
    setTimeout(() => {
      if (typeof onSlideOut === 'function') {
        onSlideOut()
      }
    }, 3000)
  }, [])

  return (
    <div className={backgroundStyle}>
      <img className={styles['balloon']} src={balloon} alt={'game balloon'} />
      <div className={styles['text']}>{message}</div>
    </div>
  )
}

export default CloudAnimation
