import React, { createContext, ReactNode, useState } from 'react'

interface GameContextProviderProps {
  children: ReactNode
}

interface GameContextProps {
  shouldEndGame: boolean
  setShouldEndGame: (arg: boolean) => void
  count: number
  setCount: (arg: number) => void
}

export const GameContext = createContext({} as GameContextProps)

export const GameContextProvider = ({ children }: GameContextProviderProps) => {
  const [shouldEndGame, setShouldEndGame] = useState(false)
  const [count, setCount] = useState(0)

  return (
    <GameContext.Provider
      value={{
        shouldEndGame,
        setShouldEndGame,
        count,
        setCount,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}
