import React, { useState } from 'react'
import styles from './CustomSelector.module.scss'

interface CustomSelectorProps {
  options: string[]
  onChange: (s: string) => void
  value: string
}

const CustomSelector = ({ options, value, onChange }: CustomSelectorProps) => {
  const [showOptions, setShowOptions] = useState(false)

  return (
    <div>
      <button
        className={styles['selector-btn']}
        type="button"
        onClick={() => setShowOptions((prev) => !prev)}
      >
        {value || 'Kontaktdagarna'}
        <i className={styles['arrow']} />
      </button>
      {showOptions && (
        <div className={styles['selector']}>
          {options.map((option: string) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <button
              type="button"
              onClick={() => {
                onChange(option)
                setShowOptions((prev) => !prev)
              }}
              className={styles['option']}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelector
