import React, { useContext, useEffect, useState } from 'react'
import { fetchLeaderBoard } from 'services/Services'
import { leaderBoardItem } from 'types/types'
import { EventContext } from 'context/Event.context'
import { useLeaderboard } from 'hooks/Hooks'
import styles from './Leaderboard.module.scss'

interface LeaderBoardProps {
  close: () => void
}

const mockData = [
  { id: 1, username: 'Oskar', score: 100, email: 'testemail1' },
  { id: 2, username: 'Andreas', score: 120, email: 'testemail2' },
  { id: 3, username: 'JP', score: 150, email: 'testemail3' },
  { id: 4, username: 'Lucas', score: 130, email: 'testemail4' },
]

const Leaderboard = ({ close }: LeaderBoardProps) => {
  const { sortedLeaderboard, rank } = useLeaderboard(
    localStorage.getItem('username') as string
  )
  const { event: eventOption } = useContext(EventContext)

  return (
    <div className={styles['leaderboard-wrapper']}>
      <div className={styles['leaderboard-content-wrapper']}>
        <div
          role="button"
          tabIndex={0}
          aria-label="close"
          className={styles['leaderboard-close']}
          onClick={() => close()}
        />
        <h1>Leaderboard</h1>
        <h2>{eventOption}</h2>
        {sortedLeaderboard.map((item: leaderBoardItem, index) => {
          if (index >= 5) return null

          return (
            <div
              className={
                styles[
                  `${
                    rank === index
                      ? 'leaderboard-item-green'
                      : 'leaderboard-item'
                  }`
                ]
              }
              key={item.id}
            >
              <span className={styles['leaderboard-item--points']}>
                {index + 1}.
              </span>
              <span>{item.username}</span>
              <span className={styles['leaderboard-item--points']}>
                {item.score}
              </span>
            </div>
          )
        })}
        <div className={styles['footer']}>
          Do you want to know more about what we do?
          <br />
          <div>click here</div>
        </div>
      </div>
    </div>
  )
}

export default Leaderboard
