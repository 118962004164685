import React, { useState } from 'react'
import OlofBalloon from 'assets/olof-balloon.png'
import { useGenerateUser } from 'hooks/Hooks'
import Button from 'components/buttons/Button'
import styles from './Intro.module.scss'
import Username from '../user/Username'
import CloudAnimation from '../cloudAnimation/CloudAnimation'

interface IntroProps {
  closeMenu: () => void
}

const Intro = ({ closeMenu }: IntroProps) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [ballonClassnames, setBallonClassnames] = useState(
    styles['intro-ballon']
  )
  const { loading, username, storedAvatar } = useGenerateUser()

  if (loading) {
    return <CloudAnimation message={'Generating user profile...'} />
  }

  return (
    <div className={styles.intro}>
      <div className={styles['intro-background']} />
      <Username username={username} storedAvatar={storedAvatar} />
      {showOverlay && <div className={styles['intro-overlay']} />}
      <div className={styles['content-wrapper']}>
        <div className={styles['intro-img-container']}>
          <img alt="Olof Balloon" src={OlofBalloon} />
        </div>
        <h2 className={styles['intro-title']}>HOW TO PLAY</h2>
        <p className={styles['intro-body']}>
          The aim of the game is to tap the balloon as many times as possible
          before the countdown time reaches 0. That’s it! Happy tapping, Artful
          Dodger!
        </p>
        <div className={styles['contained-button-wrapper']}>
          <Button
            type="button"
            onClick={() => {
              setBallonClassnames(
                `${ballonClassnames} ${styles['ballon-slide-out']}`
              )
              setShowOverlay(true)
              setTimeout(() => {
                closeMenu()
              }, 1000)
            }}
          >
            PLAY
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Intro
