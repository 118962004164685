import React from 'react'
import { ButtonTypes } from 'enums'
import styles from './Button.module.scss'

interface customButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  buttonType?: 'contained' | 'outlined' | 'text'
}

const Button = ({
  children,
  type,
  onClick = () => {},
  buttonType = 'contained',
}: customButtonProps) => (
  <button
    type={type === ButtonTypes.SUBMIT ? 'submit' : 'button'}
    className={styles[`button--${buttonType}`]}
    onClick={onClick}
  >
    {children}
  </button>
)

export default Button
