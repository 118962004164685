import React, { useContext, useState } from 'react'
import Leaderboard from 'components/gameEndScreen/leaderboard/Leaderboard'
import OlofTitle from 'assets/olofAdventureTitle.png'
import { EventContext } from 'context/Event.context'
import CustomSelector from 'components/selectors/CustomSelector'
import Button from 'components/buttons/Button'
import styles from './Welcome.module.scss'

interface WelcomeProps {
  onClick: () => void
}

const Welcome = ({ onClick }: WelcomeProps) => {
  const [startClassNames] = useState(styles.start)
  const [showLeaderboard, setShowLeaderboard] = useState(false)
  const { event: eventOption, setEvent: setEventOption } =
    useContext(EventContext)

  const eventOptions: string[] = ['KTH', 'LTH', 'LiU']

  if (showLeaderboard)
    return <Leaderboard close={() => setShowLeaderboard(false)} />

  return (
    <div className={startClassNames}>
      <div className={styles['content-wrapper']}>
        <div className={styles['image-wrapper']}>
          <img
            className={styles['background']}
            src={OlofTitle}
            alt="Olof game title"
          />
          <div className={styles['s-wrapper']}>{'\'s'}</div>
          <div className={styles['adventure-wrapper']}>ADVENTURE</div>
        </div>
        <div className={styles['input-content']}>
          <div className={styles['event-text']}>At which event are you?</div>
          <CustomSelector
            options={eventOptions}
            onChange={(s: string) => setEventOption(s)}
            value={eventOption}
          />
          <div className={styles['button-wrapper']}>
            <Button
              buttonType="text"
              type="button"
              onClick={() => setShowLeaderboard(true)}
            >
              View leaderboard
            </Button>
            <Button
              type="button"
              onClick={() => {
                onClick()
              }}
            >
              Start
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome
