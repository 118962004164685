import React, { useContext, useEffect, useRef } from 'react'
import config from 'phaser/config'
import PhaserGameScene from 'phaser/scenes/Game'
import CounterAndTimer from 'components/counterAndTimer/CounterAndTimer'
import { GameContext } from 'context/Game.context'
import background from '../../assets/rotating-background.png'
import './game.scss'

interface GameProps {
  endGame: () => void
}

const Game = ({ endGame }: GameProps) => {
  const PhaserGame = useRef<Phaser.Game>()
  const { shouldEndGame, setCount } = useContext(GameContext)

  useEffect(() => {
    if (!PhaserGame.current) {
      PhaserGame.current = new Phaser.Game(
        Object.assign(config, {
          scene: [PhaserGameScene],
        })
      )
    }

    PhaserGame.current.events.on('clicked', (count: number) => {
      setCount(count)
    })
  }, [])

  useEffect(() => {
    if (shouldEndGame) {
      PhaserGame.current?.events.emit('gameEnd')
    }
  }, [shouldEndGame])

  return (
    <>
      <CounterAndTimer endGame={endGame} />
      <div id="game" />
      <div className="container">
        <img src={background} alt="" />
      </div>
    </>
  )
}

export default Game
