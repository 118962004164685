import { AvatarContext } from 'context/Avatar.context'
import React, { useContext, useEffect, useState } from 'react'
import { fetchLeaderBoard, generateAvatar } from 'services/Services'
import { leaderBoardItem } from 'types/types'
import randomName from 'utils/randomNameUtils'

// eslint-disable-next-line import/prefer-default-export
export const useGenerateAvatar = () => {
  const [loading, setLoading] = useState(false)
  const [generatedAvatar, setGeneratedAvatar] = useState('')

  const genrate = (username: string) => {
    setLoading(true)
    generateAvatar(username)
      .then((res) => setGeneratedAvatar(res))
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false)
      })
  }

  return { genrate, generatedAvatar, loading }
}

export const useGenerateUser = () => {
  const { genrate, loading, generatedAvatar } = useGenerateAvatar()
  const { storedAvatar, setStoredAvatar } = useContext(AvatarContext)
  const [username, setUsername] = useState<string>('')

  useEffect(() => {
    if (username) return

    const userFromLocal = localStorage.getItem('username')

    if (userFromLocal) setUsername(userFromLocal)
    else {
      const userName = randomName()
      setUsername(userName)
    }
  }, [])

  useEffect(() => {
    if (username) {
      localStorage.setItem('username', username)
      genrate(username)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  useEffect(() => {
    setStoredAvatar(generatedAvatar)
  }, [generatedAvatar, setStoredAvatar])

  useEffect(() => {
    localStorage.setItem('avatar', storedAvatar)
  }, [storedAvatar])

  return { loading, storedAvatar, username }
}

export const useLeaderboard = (username: string) => {
  const [sortedLeaderboard, setSortedLeaderboard] = useState<
    Array<leaderBoardItem>
  >([])
  const [rank, setRank] = useState(-1)

  useEffect(() => {
    fetchLeaderBoard()
      .then((res: leaderBoardItem[]) => {
        const sortedRes = res.sort(
          (a: leaderBoardItem, b: leaderBoardItem) => b.score - a.score
        )
        setSortedLeaderboard(sortedRes)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    if (sortedLeaderboard.length > 0 && username) {
      const userRank = sortedLeaderboard.findIndex(
        (item: leaderBoardItem) => item.username === username
      )
      setRank(userRank)
    }
  }, [sortedLeaderboard, username])

  return { sortedLeaderboard, rank }
}
