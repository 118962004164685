import React, { useCallback, useContext, useEffect, useState } from 'react'
import GameOverOverlay from 'components/gameOverOverlay/GameOverOverlay'
import { pushHighscore } from 'services/Services'
import { GameContext } from 'context/Game.context'
import { AvatarContext } from 'context/Avatar.context'
import Button from 'components/buttons/Button'
import Leaderboard from './leaderboard/Leaderboard'
import styles from './GameEndScreen.module.scss'
import Form from './forms/Form'

interface GameEndScreenProps {
  restartGame: () => void
}

const GameEndScreen = ({ restartGame }: GameEndScreenProps) => {
  const [showLeaderboard, setShowLeaderboard] = useState(false)
  const [showOverlay, setShowOverlay] = useState(true)
  const { setShouldEndGame, count, setCount } = useContext(GameContext)
  const [email, setEmail] = useState(localStorage.getItem('email'))
  const { storedAvatar } = useContext(AvatarContext)
  const bestScore = localStorage.getItem('bestScore') as string
  const username = localStorage.getItem('username') as string

  const setBestScore = useCallback(() => {
    localStorage.setItem('bestScore', count.toString())
    pushHighscore(username, count)
  }, [username, count])

  useEffect(() => {
    if (email) localStorage.setItem('email', email)
  }, [email])

  useEffect(() => {
    if (!bestScore) {
      setBestScore()
      return
    }

    if (count > parseInt(bestScore, 10)) {
      setBestScore()
    }
  }, [bestScore, count, setBestScore])

  useEffect(() => {
    setTimeout(() => {
      setShowOverlay(false)
    }, 4300)
  }, [])

  const retstart = () => {
    setShouldEndGame(false)
    restartGame()
    setCount(0)
  }

  if (showOverlay) return <GameOverOverlay />

  if (showLeaderboard)
    return (
      <Leaderboard
        close={() => {
          setShowLeaderboard(false)
        }}
      />
    )

  return (
    <div className={styles['game-end-screen']}>
      <div className={styles['background-wrapper']} />
      <div className={styles['game-end-screen--content']}>
        <div className={styles['score-wrapper']}>
          Your Score <br /> <div>{bestScore}</div> <br /> #51 on the leaderboard
        </div>
        <div className={styles['button-wrapper']}>
          {!email && <Form onsubmit={setEmail} />}
          <Button
            type="button"
            buttonType="text"
            onClick={() => setShowLeaderboard((prev) => !prev)}
          >
            Show leaderboard
          </Button>
          <Button
            buttonType="contained"
            type="button"
            onClick={() => retstart()}
          >
            Play Again
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GameEndScreen
