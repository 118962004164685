import React, { createContext, ReactNode, useState } from 'react'

interface AvatarContextProviderProps {
  children: ReactNode
}

interface AvatarContextProps {
  storedAvatar: string
  setStoredAvatar: (arg: string) => void
}

export const AvatarContext = createContext({} as AvatarContextProps)

export const AvatarContextProvider = ({
  children,
}: AvatarContextProviderProps) => {
  const [storedAvatar, setStoredAvatar] = useState('')

  return (
    <AvatarContext.Provider value={{ storedAvatar, setStoredAvatar }}>
      {children}
    </AvatarContext.Provider>
  )
}
