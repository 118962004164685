import React from 'react'
import Counter from './counter/Counter'
import Timer from './timer/Timer'
import styles from './CounterAndTimer.module.scss'

interface CounterAndTimerProps {
  endGame: () => void
}

const CounterAndTimer = ({ endGame }: CounterAndTimerProps) => (
  <div className={styles['counter-and-time']}>
    <Counter />
    <Timer endGame={endGame} />
  </div>
)

export default CounterAndTimer
